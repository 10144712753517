<script lang="ts">
import { VueComponentClass } from "./VueComponentClass";
const BUTTON_EXPANDED_INJECTION_KEY: InjectionKey<Ref<boolean>> = Symbol(
  "BUTTON_EXPANDED_INJECTION_KEY",
);

export const useProvideButtonExpanded = (isExpanded: Ref<boolean>): void => {
  provide(BUTTON_EXPANDED_INJECTION_KEY, isExpanded);
};

const useInjectButtonExpanded = (): Ref<boolean> => {
  return inject(BUTTON_EXPANDED_INJECTION_KEY, ref(false));
};

export enum ButtonType {
  PRIMARY = "primary",
  OUTLINED = "outlined",
  SHADED = "shaded",
  TEXT = "text",
  ICON_PRIMARY = "icon_primary",
  ICON_SECONDARY = "icon_secondary",
}

export enum ButtonSize {
  LARGE = "large",
  NORMAL = "normal",
  COMPACT = "compact",
  TINY = "tiny",
}

export enum ButtonColor {
  NORMAL = "normal",
  HEADING_TEXT = "heading_text",
  DANGER = "danger",
  WARNING = "warning",
  SUCCESS = "success",
  FONT = "font",
  FONT_SECONDARY = "font_secondary",
  WHITE = "white",
}

export enum IconPosition {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}
</script>
<script setup lang="ts">
import {
  ComponentOptions,
  computed,
  inject,
  InjectionKey,
  provide,
  Ref,
  ref,
  useSlots,
} from "vue";

import CanaryLoading from "./CanaryLoading.vue";
import {
  COLOR_BLACK_1,
  COLOR_DANGER,
  COLOR_OK,
  COLOR_WARNING,
  COLOR_WHITE,
} from "./colors";
import { BorderRadius, useTheme } from "./ThemeStore";

const props = withDefaults(
  defineProps<{
    color?: ButtonColor;
    containerClass?: VueComponentClass | null;
    contentClass?: VueComponentClass | null;
    elementType?: string;
    href?: string;
    icon?: ComponentOptions<Vue> | Vue | null;
    iconPosition?: IconPosition;
    isDisabled?: boolean;
    isExpanded?: boolean | null;
    isLoading?: boolean;
    isRounded?: boolean;
    nativeType?: "button" | "submit" | "reset" | null;
    size?: ButtonSize;
    type?: ButtonType;
  }>(),
  {
    color: ButtonColor.NORMAL,
    containerClass: null,
    contentClass: null,
    elementType: undefined,
    href: undefined,
    icon: null,
    iconPosition: IconPosition.RIGHT,
    isDisabled: false,
    isExpanded: null,
    isLoading: false,
    isRounded: false,
    nativeType: null,
    size: ButtonSize.NORMAL,
    type: ButtonType.PRIMARY,
  },
);

const emit = defineEmits(["click"]);
const slots = useSlots();

const theme = useTheme();

const shouldExpand = useInjectButtonExpanded();
const buttonExpanded = computed(() => {
  if (typeof props.isExpanded === "boolean") {
    return props.isExpanded;
  }
  return shouldExpand.value ?? false;
});

const colorBackground = computed((): string => {
  switch (props.color) {
    case ButtonColor.WHITE:
      return COLOR_WHITE;
    case ButtonColor.SUCCESS:
      return COLOR_OK;
    case ButtonColor.WARNING:
      return COLOR_WARNING;
    case ButtonColor.DANGER:
      return COLOR_DANGER;
    case ButtonColor.NORMAL:
      return theme.backgroundColorButton;
    case ButtonColor.HEADING_TEXT:
      return theme.fontColorHeader;
    case ButtonColor.FONT:
      return theme.fontColorBody;
    case ButtonColor.FONT_SECONDARY:
      return theme.fontColorSecondary;
  }
});
const colorForeground = computed((): string => {
  switch (props.color) {
    case ButtonColor.WHITE:
      return COLOR_BLACK_1;
    case ButtonColor.WARNING:
    case ButtonColor.DANGER:
    case ButtonColor.SUCCESS:
      return COLOR_WHITE;
    case ButtonColor.NORMAL:
      return theme.fontColorButton;
    case ButtonColor.HEADING_TEXT:
      return theme.fontColorHeader;
    case ButtonColor.FONT:
      return theme.backgroundColorPage;
    case ButtonColor.FONT_SECONDARY:
      return theme.backgroundColorPage;
  }
});
const border = computed((): string => {
  switch (props.type) {
    case ButtonType.PRIMARY:
    case ButtonType.TEXT:
    case ButtonType.ICON_PRIMARY:
    case ButtonType.ICON_SECONDARY:
    case ButtonType.SHADED:
      return "none";
    case ButtonType.OUTLINED:
      return `1px solid ${colorBackground.value}`;
  }
});
const contentColor = computed((): string => {
  switch (props.type) {
    case ButtonType.PRIMARY:
    case ButtonType.ICON_PRIMARY:
      return colorForeground.value;
    case ButtonType.OUTLINED:
    case ButtonType.TEXT:
    case ButtonType.ICON_SECONDARY:
    case ButtonType.SHADED:
      return colorBackground.value;
  }
});
const opacity = computed((): string => {
  return props.isDisabled ? "0.5" : "1";
});
const borderRadius = computed((): string => {
  switch (theme.borderRadius) {
    case BorderRadius.SQUARE:
      return "0";
    case BorderRadius.CIRCULAR:
      return "50px";
    case BorderRadius.ROUND:
      return "4px";
  }
});
const style = computed((): Partial<CSSStyleDeclaration> => {
  return {
    border: border.value,
    color: contentColor.value,
    opacity: opacity.value,
    borderRadius: borderRadius.value,
  };
});
const hasIcon = computed(() => {
  return slots.icon || props.icon;
});
const iconStyle = computed((): { fill: string } => {
  return { fill: contentColor.value };
});
const isIconType = computed((): boolean => {
  return (
    props.type == ButtonType.ICON_PRIMARY ||
    props.type == ButtonType.ICON_SECONDARY
  );
});

const onClick = (event: Event) => {
  if (!props.isLoading && !props.isDisabled) {
    emit("click", event);
  }
};
</script>

<template>
  <component
    :is="elementType ?? (href ? 'a' : 'button')"
    :class="[
      $style.button,
      {
        [$style['button--expanded']]: buttonExpanded,
        [$style['button--outlined']]: type == ButtonType.OUTLINED,
        [$style['button--text']]: type == ButtonType.TEXT,
        [$style['button--shaded']]: type == ButtonType.SHADED,
        [$style['button--iconPrimary']]: type == ButtonType.ICON_PRIMARY,
        [$style['button--iconSecondary']]: type == ButtonType.ICON_SECONDARY,
        [$style['button--sizeLarge']]: size == ButtonSize.LARGE,
        [$style['button--sizeCompact']]: size == ButtonSize.COMPACT,
        [$style['button--sizeTiny']]: size == ButtonSize.TINY,
        [$style['button--disabled']]: isDisabled,
        [$style['button--loading']]: isLoading,
        [$style['button--rounded']]: isRounded,
        [$style['button--iconLeft']]:
          !isIconType && hasIcon && iconPosition == IconPosition.LEFT,
        [$style['button--iconRight']]:
          !isIconType && hasIcon && iconPosition == IconPosition.RIGHT,
        [$style['button--iconTop']]:
          !isIconType && hasIcon && iconPosition == IconPosition.TOP,
      },
      containerClass,
    ]"
    :style="style"
    :disabled="isDisabled"
    :type="nativeType"
    :href="href"
    :target="href ? '_self' : undefined"
    @click="onClick"
  >
    <span
      :class="$style.background"
      :style="{ background: colorBackground, borderRadius: borderRadius }"
    ></span>
    <CanaryLoading
      v-if="isLoading"
      :class="$style.loading"
      :color="contentColor"
    />
    <span :class="[$style.content, contentClass]">
      <slot v-if="!isIconType"></slot>
      <slot name="icon">
        <component
          :is="icon"
          v-if="icon"
          :class="$style.icon"
          :style="iconStyle"
        />
      </slot>
    </span>
  </component>
</template>

<style lang="scss" module>
@use "sass:math";
@import "shared/styles/base/typography.scss";

$size-large: 48px;
$size-normal: 40px;
$size-compact: 32px;
$size-tiny: 28px;

$text-padding: 16px;
$text-padding-tiny: 8px;

$icon-size: 24px;
$icon-padding: 8px;
$icon-padding-tiny: 4px;
$icon-total-width: ($icon-padding * 2) + $icon-size;
$icon-total-width-tiny: ($icon-padding-tiny * 2) + $icon-size;

// use really high pixel value to get both pilled and circular buttons
// 50% achieves the circular effect but will make eclipse when width > height
$rounded-border-radius: 999999px;

.button {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  // Specifying font-family here as browsers use the OS theme instead.
  // Likely monospaced without specifying. See link:
  // https://stackoverflow.com/questions/2874813/why-textarea-and-textfield-not-taking-font-family-and-font-size-from-body
  font-family: var(--canaryThemeFontFamilyBody);
  @include font-style($body, 400);
  height: $size-normal;
  justify-content: center;
  line-height: 24px;
  outline: none;
  padding: 0 $text-padding;
  position: relative;
  text-decoration: none;

  &--sizeLarge {
    font-size: 16px;
    height: $size-large;
  }

  &--sizeCompact {
    @include font-style($body-sm, 400);
    height: $size-compact;
  }

  &--sizeTiny {
    @include font-style($body-sm, 400);
    height: $size-tiny;
    padding: 0 $text-padding-tiny;
  }

  &--outlined {
    padding: 0 $text-padding - 1px;
    &.button--sizeTiny {
      padding: 0 $text-padding-tiny - 1px;
    }
  }

  &--expanded {
    flex: 1 0 auto;
    padding: 0 math.div($text-padding, 2);
    &.button--sizeTiny {
      padding: 0 math.div($text-padding-tiny, 2);
    }
  }

  &.button--outlined,
  &.button--text,
  &.button--iconSecondary {
    .background {
      opacity: 0;
    }
  }
  &.button--shaded {
    .background {
      opacity: 0.1;
    }
    &:not(.button--disabled) {
      &:hover,
      &:focus {
        .background {
          opacity: 0.25;
        }
      }
    }
    &:active {
      .background {
        opacity: 0.5;
      }
    }
  }

  &:not(.button--loading):not(.button--disabled) {
    &:hover,
    &:focus {
      .content {
        opacity: 0.8;
      }
    }
    &:active {
      .content {
        opacity: 0.6;
      }
    }
    &.button--outlined,
    &.button--text,
    &.button--iconSecondary {
      &:hover,
      &:focus {
        .background {
          opacity: 0.08;
        }
      }
      &:active {
        .background {
          opacity: 0.16;
        }
      }
      .content {
        opacity: 1;
      }
    }
  }
  &--disabled {
    cursor: default;
  }
  &--rounded {
    border-radius: $rounded-border-radius !important;
    .background {
      border-radius: $rounded-border-radius !important;
    }
  }
  &--iconRight {
    padding: 0 $icon-padding 0 $text-padding;
    &.button--sizeTiny {
      padding: 0 $icon-padding-tiny 0 $text-padding-tiny;
    }
    [dir="rtl"] & {
      padding: 0 $text-padding 0 $icon-padding;
      &.button--sizeTiny {
        padding: 0 $text-padding-tiny 0 $icon-padding-tiny;
      }
    }
    &.button--expanded {
      padding: 0 $icon-total-width 0 $icon-total-width;
      &.button--sizeTiny {
        padding: 0 $icon-total-width-tiny 0 $icon-total-width-tiny;
      }
    }
  }
  &--iconLeft {
    flex-direction: row-reverse;
    padding: 0 $text-padding 0 $icon-padding;
    &.button--sizeTiny {
      padding: 0 $text-padding-tiny 0 $icon-padding-tiny;
    }
    [dir="rtl"] & {
      padding: 0 $icon-padding 0 $text-padding;
      &.button--sizeTiny {
        padding: 0 $icon-padding-tiny 0 $text-padding-tiny;
      }
    }
    &.button--expanded {
      padding: 0 $icon-total-width 0 $icon-total-width;
      &.button--sizeTiny {
        padding: 0 $icon-total-width-tiny 0 $icon-total-width-tiny;
      }
    }
  }
  &--iconTop {
    flex-direction: column-reverse;
    padding: calc($icon-padding * 2); // pending design ACK
    &.button--sizeTiny {
      padding: calc($icon-padding-tiny * 2); // pending design ACK
    }
    &.button--expanded {
      padding: $icon-total-width;
      &.button--sizeTiny {
        padding: $icon-total-width-tiny;
      }
    }
    height: auto;
  }
  &--iconPrimary,
  &--iconSecondary {
    border-radius: 50%;
    width: $size-normal;
    svg {
      height: 20px;
      width: 20px;
    }
    .background {
      border-radius: 50% !important;
    }
    &,
    [dir="rtl"] & {
      padding: 0;
    }
    &.button--sizeLarge {
      width: $size-large;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    &.button--sizeCompact {
      width: $size-compact;
    }
    &.button--sizeTiny {
      width: $size-tiny;
    }
  }
}

.content {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  min-width: 0;
  position: relative;
  width: 100%;
  .button--iconLeft & {
    flex-direction: row-reverse;
  }
  .button--iconTop & {
    flex-direction: column-reverse;
  }
  .button--loading & {
    opacity: 0;
  }
}

.background {
  border-radius: 4px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.loading {
  position: absolute !important;
}

.icon {
  [dir="rtl"] & {
    transform: scaleX(-1);
  }

  .button--iconRight & {
    margin: 0 0 0 $icon-padding;
    [dir="rtl"] & {
      margin: 0 $icon-padding 0 0;
    }
  }
  .button--iconRight.button--sizeTiny & {
    margin: 0 0 0 $icon-padding-tiny;
    [dir="rtl"] & {
      margin: 0 $icon-padding-tiny 0 0;
    }
  }
  .button--iconLeft & {
    margin: 0 $icon-padding 0 0;
    [dir="rtl"] & {
      margin: 0 0 0 $icon-padding;
    }
  }
  .button--iconLeft.button--sizeTiny & {
    margin: 0 $icon-padding-tiny 0 0;
    [dir="rtl"] & {
      margin: 0 0 0 $icon-padding-tiny;
    }
  }
  .button--iconTop & {
    margin: 0 0 calc($icon-padding/2) 0;
  }
  .button--iconTop.button--sizeTiny & {
    margin: 0 0 calc($icon-padding-tiny/2) 0;
  }

  .button--expanded & {
    position: absolute;
  }
  .button--expanded.button--iconRight & {
    right: -1 * ($icon-padding + $icon-size);
    [dir="rtl"] & {
      left: -1 * ($icon-padding + $icon-size);
      right: unset;
    }
  }
  .button--expanded.button--iconRight.button--sizeTiny & {
    right: -1 * ($icon-padding-tiny + $icon-size);
    [dir="rtl"] & {
      left: -1 * ($icon-padding-tiny + $icon-size);
    }
  }
  .button--expanded.button--iconLeft & {
    left: -1 * ($icon-padding + $icon-size);
    [dir="rtl"] & {
      left: unset;
      right: -1 * ($icon-padding + $icon-size);
    }
  }
  .button--expanded.button--iconLeft.button--sizeTiny & {
    left: -1 * ($icon-padding-tiny + $icon-size);
    [dir="rtl"] & {
      right: -1 * ($icon-padding-tiny + $icon-size);
    }
  }
  .button--expanded.button--iconTop & {
    top: -1 * ($icon-padding + $icon-size);
  }
  .button--expanded.button--iconTop.button--sizeTiny & {
    top: -1 * ($icon-padding-tiny + $icon-size);
  }
}
</style>
