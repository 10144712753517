import { Component, inject, provide } from "vue";
import { AsyncComponent } from "vue";
import type { TranslateResult } from "vue-i18n";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ComponentProps } from "shared/types/Component";

import { ToastType } from "./ToastType";

export const NAMESPACE = "toast";

const TOAST_STORE_INJECTION_KEY = Symbol("ToastStore");
/**
 * Individual frontend apps should use this in App.vue to ensure
 * that non-app-specific vue files (e.g. Canary UI components)
 * will be able to inject a valid store.
 */
export const provideToastStore = (toastStore: ToastStore): void =>
  provide(TOAST_STORE_INJECTION_KEY, toastStore);
/**
 * This can be used by .vue files outside of specific apps
 * (e.g. Canary UI components) to inject the app-specific toast store
 * instance.
 * NOTE: Depends on use provideToastStore() within the relevant app.
 */
export const injectToastStore = (): ToastStore | null =>
  inject<ToastStore | null>(TOAST_STORE_INJECTION_KEY, null);

export type VueComponent = Component | AsyncComponent;

export enum ToastPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum ToastTransition {
  DEFAULT = "default",
  POP = "pop",
}

export type ToastParams<TComponent extends VueComponent = VueComponent> = {
  position?: ToastPosition;
  transition?: ToastTransition;
  offset?: number;
} & (
  | {
      text: string | TranslateResult;
      type?: ToastType;
    }
  | {
      component: TComponent;
      props?: ComponentProps<TComponent>;
      events?: Record<string, (event: object) => void | Promise<void>>;
    }
);

@Module({ name: NAMESPACE, namespaced: true, stateFactory: true })
export default class ToastStore extends VuexModule {
  queuedToasts: ToastParams[] = [];

  get active(): ToastParams | null {
    return this.queuedToasts.length ? this.queuedToasts[0] : null;
  }

  @Mutation
  toast(params: ToastParams): void {
    this.queuedToasts.push(params);
  }

  @Mutation
  clearActive(): void {
    if (this.queuedToasts.length) {
      this.queuedToasts.shift();
    }
  }
}
