function convertTo6DigitHex(color: string): string {
  if (color.length >= 6) return color;
  if (color.length != 3) return color + "0".repeat(6 - color.length);
  const r = color[0];
  const g = color[1];
  const b = color[2];
  return `${r}${r}${g}${g}${b}${b}`;
}

export function convertHexToDecimals(color: string): string {
  const fullColor = convertTo6DigitHex(color.replace("#", ""));
  return [
    parseInt(fullColor.slice(0, 2), 16),
    parseInt(fullColor.slice(2, 4), 16),
    parseInt(fullColor.slice(4, 6), 16),
  ].join(",");
}

export function parseThemeColor(color: string): string {
  return convertTo6DigitHex(color.replace("#", ""));
}

export function getGradient(color: string): string {
  return `linear-gradient(to bottom, #${color} 0, #${color} 1, )`;
}
