export type Theme = {
  primary_color: string;
  background_color: string;
  font_color: string;
  font_color_secondary: string;
  font_color_accent: string;
  button_color: string;
  button_font_color: string;
};

/**
 * Takes a theme object (which maps fields to colors) and generates a stylesheet
 * which is added to the document head for customizing font colors / background colors
 * in addition to custom overriding of buefy component styles.
 *
 * @param {Object} theme - An object mapping between fields and color values.
 *  e.g. theme = {
 *    primary_color: "FC4A8E",     // Background color for the header and success screen.
 *    background_color: "333",     // Background color for the page.
 *    font_color: "FC4A8E",        // Font color for the majority of text.
 *    font_color_secondary: "FFF", // Font color for title text (h3's, h4's).
 *    font_color_accent: "FFF",    // Font color used on text that is on top of the primary color.
 *    button_color: "FC4A8E",      // Color of all buttons.
 *    button_font_color: "FFF"     // Color of font on all buttons.
 *  }
 */
export function injectTheme(theme: Theme, previewMode?: boolean): void {
  const style = document.createElement("style");

  style.id = "canary-theme";

  style.innerHTML = `
    ${
      !previewMode
        ? `
            html {
              transition: background-color 1s ease;
              background-color: #${theme.background_color} !important;
            }
        `
        : ""
    }
    .canary-theme--background-color {
      background-color: #${theme.background_color} !important;
    }

    .canary-theme--font-color {
      color: #${theme.font_color} !important;
      border-color: #${theme.font_color} !important;
    }
    .canary-theme--font-color-secondary {
      color: #${theme.font_color_secondary} !important;
      border-color: #${theme.font_color} !important;
    }
    .canary-theme--font-color-secondary:hover {
      color: #${theme.font_color_secondary} !important;
    }
    .canary-theme--font-color-accent {
      color: #${theme.font_color_accent} !important;
    }
    .canary-theme--font-color-accent path {
      fill: #${theme.font_color_accent} !important;
    }

    .canary-theme--primary-color--background {
      background-color: #${theme.primary_color} !important;
    }
    .canary-theme--primary-color--fill,
    .canary-theme--primary-color--fill path {
      fill: #${theme.primary_color} !important;
    }

    .canary-theme--radio-color .check {
      border-color: #${theme.button_color} !important;
    }
    .canary-theme--radio-color .check:before {
      background-color: #${theme.button_color} !important;
    }
    .canary-theme--radio-color-inverted .check {
      border-color: #${theme.button_font_color} !important;
    }
    .canary-theme--radio-color-inverted .check:before {
      background-color: #${theme.button_font_color} !important;
    }

    .canary-theme--button-color {
      background-color: #${theme.button_color} !important;
      border-color: transparent;
    }
    .canary-theme--button-color:hover {
      filter: brightness(98%);
      border-color: transparent;
    }
    .canary-theme--button-color-inverted {
      background-color: #${theme.button_font_color} !important;
      border-width: 2px;
      border-color: #${theme.button_color} !important;
      color: #${theme.button_color} !important;
    }
    .canary-theme--button-color:disabled {
      background-color: #${theme.button_color} !important;
      border-color: transparent;
      opacity: 30%;
    }
    .canary-theme--button-font-color {
      color: #${theme.button_font_color} !important;
    }
    .canary-theme--button-font-color:hover {
      color: #${theme.button_font_color} !important;
    }
    .canary-theme--button-font-color:focus {
      color: #${theme.button_font_color} !important;
    }
    .canary-theme--button-font-color.button.is-loading {
      color: transparent !important;
    }
    .canary-theme--button-font-color.button.is-loading::after {
      border-color: transparent transparent #${theme.button_font_color} #${
        theme.button_font_color
      } !important;
    }
    .canary-theme--border-color {
      border-color: #${theme.font_color} !important;
    }
    .canary-theme--tag-font-color {
      color: #${theme.font_color};
      border-color: #${theme.font_color};
    }
    .canary-theme--tag-font-color-secondary {
      color: #${theme.font_color_secondary};
      border-color: #${theme.font_color};
    }
    .canary-theme--tag-button-color {
      color: #${theme.button_color} !important;
      border-color: #${theme.button_color} !important;
    }
  `;

  const canaryThemeEl = document.head.querySelector("#canary-theme");
  if (canaryThemeEl) {
    document.head.removeChild(canaryThemeEl);
  }

  document.head.appendChild(style);
}

export default injectTheme;
