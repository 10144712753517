import { inject, provide } from "vue";
import { Position, Route } from "vue-router/types/router";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const NAMESPACE = "router";

export type RestoreScrollPositionCallback = (
  to: Route,
  from: Route,
  savedPosition: Position | null,
) => void;

const ROUTER_STORE_INJECTION_KEY = Symbol("RouterStore");
/**
 * Individual frontend apps should use this in App.vue to ensure
 * that non-app-specific vue files (e.g. Canary UI components)
 * will be able to inject a valid store.
 */
export const provideRouterStore = (routerStore: RouterStore): void =>
  provide(ROUTER_STORE_INJECTION_KEY, routerStore);
/**
 * This can be used by .vue files outside of specific apps
 * (e.g. Canary UI components) to inject the app-specific toast store
 * instance.
 * NOTE: Depends on use provideRouterStore() within the relevant app.
 */
export const injectRouterStore = (): RouterStore | null =>
  inject<RouterStore | null>(ROUTER_STORE_INJECTION_KEY, null);

@Module({ name: NAMESPACE, namespaced: true, stateFactory: true })
export default class RouterStore extends VuexModule {
  callbacks: RestoreScrollPositionCallback[] = [];
  previousRoute: Route | null = null;

  @Mutation
  setPreviousRoute(route: Route): void {
    this.previousRoute = route;
  }

  /**
   * Invoke from Vue Route's `scrollBehavior` hook to notify CanaryUI components.
   *
   * This store is acting as an event source for all of the `CanaryRouterView`'s to register
   * against. We needed some way to connect the Vue Router configuration to multiple components
   * throughout the system.
   * ```
   * const router = new Router({
   *   mode: "history",
   *   routes: [ ... ],
   *   scrollBehavior(to, from, savedPosition) {
   *     routerStore.onRestoreScrollPosition(to, from, savedPosition || null);
   *     return { x: 0, y: 0 };
   *   },
   * });
   * ```
   */
  @Action({ rawError: true })
  async onRestoreScrollPosition({
    to,
    from,
    savedPosition,
  }: {
    to: Route;
    from: Route;
    savedPosition: Position | null;
  }): Promise<void> {
    this.callbacks.forEach(callback => callback(to, from, savedPosition));
  }

  @Mutation
  addRestoreScrollPositionCallback(
    callback: RestoreScrollPositionCallback,
  ): void {
    this.callbacks = this.callbacks.concat(callback);
  }

  @Mutation
  removeRestoreScrollPositionCallback(
    callback: RestoreScrollPositionCallback,
  ): void {
    this.callbacks = this.callbacks.filter(x => x != callback);
  }
}
