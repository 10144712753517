import Vue, { AsyncComponent, Component, Ref } from "vue";
import type { TranslateResult } from "vue-i18n";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { asType } from "shared/helpers/asType";
import { ComponentProps } from "shared/types/Component";

import { getUniqueId } from "../utils/Ids";
import AlertModal, { AlertType } from "./AlertModal.vue";
import ConfirmModal, { ConfirmType } from "./ConfirmModal.vue";

export const NAMESPACE = "modal";

export type VueComponent = Component | AsyncComponent;
export enum ModalAnimation {
  POP = "pop",
  SLIDE = "slide",
}

export interface ModalParams<C extends VueComponent = VueComponent> {
  component: VueComponent;
  props?: ComponentProps<C>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events?: Record<string, (event: any) => void | Promise<void>>;
  animation?: ModalAnimation;
  closeOnBackgroundClick?: boolean;
  closeOnEscape?: boolean;
}

export interface ConfirmModalPropsAndEvents {
  title: string | TranslateResult;
  message: string | TranslateResult;
  cancelText?: string | TranslateResult;
  confirmText?: string | TranslateResult;
  type?: ConfirmType;
  isCancelLoading?: boolean | Ref<boolean>;
  isConfirmLoading?: boolean | Ref<boolean>;
  showCloseIcon?: boolean | Ref<boolean>;
  onCancel?: () => Promise<void> | void;
  onConfirm?: () => Promise<void> | void;
}

export type AlertModalPropsAndEvents = {
  title: string | TranslateResult;
  message: string | TranslateResult;
  acknowledgeText?: string | TranslateResult;
  type?: AlertType;
  onAcknowledge?: () => Promise<void> | void;
};

export type ModalId = string;

export interface VisibleModal<T extends VueComponent = VueComponent> {
  id: ModalId;
  params: ModalParams<T>;
}

@Module({ name: NAMESPACE, namespaced: true, stateFactory: true })
export default class ModalStore extends VuexModule {
  visibleModals: VisibleModal[] = [];
  /**
   * Track which modal is being closed so we can animate the
   * closing properly
   */
  closingModal: VisibleModal | null = null;

  get hasActiveModal(): boolean {
    return this.visibleModals.length != 0;
  }

  get activeModal(): VisibleModal | null {
    return this.visibleModals.length ? this.visibleModals.slice(-1)[0] : null;
  }

  @Action({ rawError: true })
  async show<T extends VueComponent>(params: ModalParams<T>): Promise<ModalId> {
    const id = getUniqueId();
    Object.values(params.events || {}).forEach(eventHandler => {
      eventHandler.bind({ ...this, selfId: id });
    });
    this.visibleModals.push({ id, params });
    return id;
  }

  /**
   * Open a `CanaryConfirmModal` with given pass-through attributes
   * @param params
   * @returns Promise<boolean>
   */
  @Action({ rawError: true })
  async confirm({
    onCancel,
    ...props
  }: ConfirmModalPropsAndEvents): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const self = asType<ModalStore & { selfId: string }>(this);
      this.show({
        component: ConfirmModal,
        props,
        events: {
          cancel: () => {
            onCancel?.();
            this.close({ modalId: self.selfId });
            resolve(false);
          },
          confirm: async () => {
            await props.onConfirm?.();
            this.close({ modalId: self.selfId });
            resolve(true);
          },
        },
      });
    });
  }

  /**
   * Open a `CanaryAlertModal` with given pass-through attributes
   * @param params
   * @returns Promise<boolean>
   */
  @Action({ rawError: true })
  async alert({
    onAcknowledge,
    ...props
  }: AlertModalPropsAndEvents): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const self = asType<ModalStore & { selfId: string }>(this);
      self.show({
        component: AlertModal,
        props,
        events: {
          acknowledge: () => {
            onAcknowledge?.();
            this.close({ modalId: self.selfId });
            resolve(false);
          },
        },
      });
    });
  }

  @Mutation
  updateProps({
    updates,
    modalId,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updates: Record<string, any>;
    modalId?: ModalId | undefined | null;
  }): void {
    const activeModal = this.visibleModals.slice(-1)[0];
    if (!activeModal) return;

    const visibleModal = modalId
      ? this.visibleModals.find(modal => modal.id === modalId)
      : activeModal;
    if (!visibleModal) {
      return;
    }
    if (!visibleModal.params.props) {
      visibleModal.params.props = {};
    }
    const props = visibleModal.params.props;
    Object.keys(updates).forEach(key => {
      Vue.set(props, key, updates[key]);
    });
  }

  @Mutation
  close({ modalId }: { modalId?: string | null } = {}): void {
    const index = modalId
      ? this.visibleModals.findIndex(modal => modal.id === modalId)
      : this.visibleModals.length - 1;
    if (index >= 0) {
      [this.closingModal] = this.visibleModals.splice(index, 1);
    }
  }

  @Mutation
  finishClose(): void {
    this.closingModal = null;
  }
}
