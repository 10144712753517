/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

/* Helper method to ignore type error in template(not supported in Vue2)
   https://github.com/johnsoncodehk/volar/issues/176
 */
export const asType = <T>(param: any): T => {
  return param as T;
};

/* Helper method to ignore type error in template(not supported in Vue2)
   https://github.com/johnsoncodehk/volar/issues/176
 */
export const asAny = asType<any>;
