<script lang="ts">
export enum AlertType {
  ERROR = "error",
  NORMAL = "normal",
}
</script>

<script setup lang="ts">
import type { TranslateResult } from "vue-i18n";

import CanaryButton, {
  ButtonColor,
  ButtonSize,
} from "canary-ui/src/CanaryButton.vue";
import CanaryModal from "canary-ui/src/modals/CanaryModal.vue";

const props = withDefaults(
  defineProps<{
    title: string | TranslateResult;
    message: string | TranslateResult;
    type: AlertType;
    acknowledgeText?: string | TranslateResult;
  }>(),
  {
    acknowledgeText: "OK",
  },
);

defineEmits(["acknowledge"]);

const acknowledgePendo = (): string => {
  return `${props.title.toString().toLowerCase()} - acknowledge`;
};
</script>
<template>
  <CanaryModal :class="$style.modal">
    <div :class="$style.content">
      <div :class="$style.title">
        {{ title }}
      </div>
      <div :class="$style.message">
        <slot>{{ message }}</slot>
      </div>

      <div :class="$style.footer">
        <CanaryButton
          :size="ButtonSize.NORMAL"
          :color="
            type === AlertType.ERROR ? ButtonColor.DANGER : ButtonColor.NORMAL
          "
          :class="$style.button"
          :data-pendo="acknowledgePendo"
          @click="$emit('acknowledge')"
        >
          {{ acknowledgeText }}
        </CanaryButton>
      </div>
    </div>
  </CanaryModal>
</template>

<style lang="scss" module>
@import "shared/styles/base/typography.scss";

$gutter-gap-spacing: 16px;

.modal {
  margin: 16px;
  width: 480px;
  padding: 24px;
}

.title {
  @include font-style($subtitle-lg, 500);
  display: flex;
  justify-content: space-between;
}

.message {
  padding: 16px 0 24px;
  color: var(--canaryThemeFontColorSecondary);
  @include font-style($body);
}

.footer {
  align-items: center;
  display: flex;
  gap: $gutter-gap-spacing;
  justify-content: flex-end;
}
</style>
