import { defineStore } from "pinia";
import { computed, inject, ref } from "vue";

import injectTheme from "shared/helpers/injectTheme";
import { Theme } from "shared/schemas/checkIn/Theme";

import { CANARY_SCOPED_THEME_PROVIDER } from "./CanaryScopedTheme.types";
import {
  convertHexToDecimals,
  getGradient,
  parseThemeColor,
} from "./utils/theme";

export enum BorderRadius {
  SQUARE = "square",
  ROUND = "round",
  CIRCULAR = "circular",
}

export enum FontFamily {
  ROBOTO = "Roboto, sans-serif",
  ROBOTO_SLAB = "Roboto Slab, serif",
  SOURCE_SERIF = "Source Serif 4, serif",
}

export enum FontType {
  HEADER = "header",
  BODY = "body",
}

export interface RadiusTheme {
  button: string;
  input: string;
  card: string;
  progress_bar: string;
}

const DEFAULT_THEME: Theme = {
  /** TODO: COR-1193 Rename to page_background_color. Background color of entire page.  */
  background_color: "f9f9f9",
  /** TODO: Rename to font_color-body. Font color for body content.*/
  font_color: "1c1c1c",
  /** Secondary font color. */
  font_color_secondary: "555",
  /** TODO: COR-1193 Rename to background_color_button. Background color of button.  */
  button_color: "4481e6",
  /** TODO: COR-1193 Rename to font_color_button. Font color of button.  */
  button_font_color: "fff",
  /** TODO: COR-1193 Rename to background_color_header. Background color of for the header. */
  primary_color: "4481e6",
  /** TODO: COR-1193 Rename to font_color_header. Font color for header. */
  font_color_accent: "fff",
  /** Font family for header. */
  font_family_header: FontFamily.ROBOTO,
  /** Font family for body content. */
  font_family_body: FontFamily.ROBOTO,
  /** Border radius for elements. It includes inputs, cards, progress bar and buttons. */
  border_radius: BorderRadius.ROUND,
  /** Background color for the card. */
  background_color_card: "ffffff",
  /** Boolean for indicating if the header background should be shown as a gradient. */
  has_gradient_header: false,
};

export interface ComputedTheme {
  backgroundColorPage: string;
  backgroundColorButton: string;
  backgroundColorCard: string;
  backgroundColorHeader: string;
  fontColorBody: string;
  fontColorSecondary: string;
  fontColorHeader: string;
  fontColorButton: string;
  fontFamilyHeader: FontFamily;
  fontFamilyBody: FontFamily;
  borderRadius: BorderRadius;
}

/** `useTheme` should be always used in your components since a theme might be injected. Please do not use useThemStore directly unless for demo pane. */
export const useTheme = (): ComputedTheme => {
  const themeStore = useThemeStore();
  const scopedTheme = inject<Theme | null>(CANARY_SCOPED_THEME_PROVIDER, null);
  if (scopedTheme) {
    const fontFamilyHeaderTemp: FontFamily =
      scopedTheme.font_family_header ??
      DEFAULT_THEME.font_family_header ??
      FontFamily.ROBOTO;
    return {
      backgroundColorPage:
        `#${parseThemeColor(scopedTheme.background_color)}` ||
        DEFAULT_THEME.background_color,
      fontColorBody: `#${parseThemeColor(scopedTheme.font_color)}`,
      fontColorSecondary: `#${parseThemeColor(
        scopedTheme.font_color_secondary,
      )}`,
      backgroundColorHeader: `#${parseThemeColor(scopedTheme.primary_color)}`,
      fontColorHeader: `#${parseThemeColor(scopedTheme.font_color_accent)}`,
      backgroundColorButton: `#${parseThemeColor(scopedTheme.button_color)}`,
      fontColorButton: `#${parseThemeColor(scopedTheme.button_font_color)}`,
      fontFamilyHeader: fontFamilyHeaderTemp,
      fontFamilyBody:
        scopedTheme.font_family_body ??
        DEFAULT_THEME.font_family_body ??
        FontFamily.ROBOTO,
      borderRadius: scopedTheme.border_radius || BorderRadius.ROUND,
      backgroundColorCard: `#${parseThemeColor(
        scopedTheme.background_color_card ||
          DEFAULT_THEME.background_color_card ||
          "",
      )}`,
    };
  }
  return themeStore;
};

/** use `useTheme` to access the `Theme` in your components. It wraps useThemeStore and provides its functionality. */
export const useThemeStore = defineStore("ThemeStore", () => {
  const initialized = ref<boolean>(false);
  const theme = ref<Theme>(DEFAULT_THEME);

  const backgroundColorPage = computed(() => {
    return theme.value.background_color
      ? `#${parseThemeColor(theme.value.background_color)}`
      : DEFAULT_THEME.background_color;
  });
  const fontColorBody = computed(() => {
    return theme.value.font_color
      ? `#${parseThemeColor(theme.value.font_color)}`
      : DEFAULT_THEME.font_color;
  });
  const fontColorSecondary = computed(() => {
    return theme.value.font_color_secondary
      ? `#${parseThemeColor(theme.value.font_color_secondary)}`
      : DEFAULT_THEME.font_color_secondary;
  });
  const backgroundColorHeader = computed(() => {
    return theme.value.primary_color
      ? `#${parseThemeColor(theme.value.primary_color)}`
      : DEFAULT_THEME.primary_color;
  });
  const fontColorHeader = computed(() => {
    return theme.value.font_color_accent
      ? `#${parseThemeColor(theme.value.font_color_accent)}`
      : DEFAULT_THEME.font_color_accent;
  });
  const backgroundColorButton = computed(() => {
    return theme.value.button_color
      ? `#${parseThemeColor(theme.value.button_color)}`
      : DEFAULT_THEME.button_color;
  });
  const fontColorButton = computed(() => {
    return theme.value.button_font_color
      ? `#${parseThemeColor(theme.value.button_font_color)}`
      : DEFAULT_THEME.button_font_color;
  });

  const fontFamilyHeader = computed(() => {
    return theme.value.font_family_header
      ? theme.value.font_family_header
      : DEFAULT_THEME.font_family_header ?? FontFamily.ROBOTO;
  });

  const fontFamilyBody = computed(() => {
    return theme.value.font_family_body
      ? theme.value.font_family_body
      : DEFAULT_THEME.font_family_body
        ? DEFAULT_THEME.font_family_body
        : FontFamily.ROBOTO;
  });

  const borderRadius = computed(() => {
    return theme.value.border_radius
      ? theme.value.border_radius
      : DEFAULT_THEME.border_radius
        ? DEFAULT_THEME.border_radius
        : BorderRadius.ROUND;
  });

  const backgroundColorCard = computed(() => {
    return `#${parseThemeColor(theme.value.background_color_card || "")}`;
  });

  const gradientHeader = computed(() => {
    return theme.value.has_gradient_header
      ? `linear-gradient(to bottom, #${parseThemeColor(
          theme.value.font_color_accent,
        )},
    )}, 0), #${parseThemeColor(theme.value.font_color_accent)}, 1))`
      : "";
  });
  const gradientBackground = computed(() => {
    return `linear-gradient(to bottom, rgba(${convertHexToDecimals(
      theme.value.button_color,
    )}, 0.75), rgba(${convertHexToDecimals(theme.value.button_color)}, 1))`;
  });

  function setTheme(newTheme: Theme): void {
    theme.value = newTheme;
    injectTheme(newTheme);
    updateThemeCssVariables(newTheme);
    document.documentElement.style.backgroundColor = `#${newTheme.background_color}`;
    document.documentElement.style.color = `#${newTheme.font_color}`;
    document.body.style.color = `#${newTheme.font_color}`;
    initialized.value = true;
  }

  return {
    isInitialized: computed(() => initialized.value),
    backgroundColorPage,
    fontColorBody,
    fontColorSecondary,
    backgroundColorHeader,
    fontColorHeader,
    backgroundColorButton,
    fontColorButton,
    fontFamilyHeader,
    fontFamilyBody,
    borderRadius,
    backgroundColorCard,
    gradientHeader,
    gradientBackground,
    setTheme,
  };
});

function updateThemeCssVariables(theme: Theme): void {
  updateThemeColorCssVariable(
    "canaryThemeBackgroundColor",
    theme.background_color,
  );
  updateThemeColorCssVariable("canaryThemeFontColor", theme.font_color);
  updateThemeColorCssVariable(
    "canaryThemeFontColorSecondary",
    theme.font_color_secondary,
  );
  updateThemeColorCssVariable("canaryThemeHeaderColor", theme.primary_color);
  updateThemeColorCssVariable("canaryLinkColor", theme.primary_color);
  updateThemeColorCssVariable(
    "canaryThemeFontColorHeader",
    theme.font_color_accent,
  );
  updateThemeColorCssVariable("canaryThemeButtonColor", theme.button_color);
  updateThemeColorCssVariable(
    "canaryThemeFontColorButton",
    theme.button_font_color,
  );
  updateThemeCssVariable(
    "canaryThemeFontFamilyHeader",
    theme.font_family_header || "",
  );
  updateThemeCssVariable(
    "canaryThemeFontFamilyBody",
    theme.font_family_body || "",
  );
  updateThemeColorCssVariable(
    "canaryThemeBackgroundColorCard",
    theme.background_color_card || "",
  );
  updateThemeColorCssVariable(
    "canaryThemeGradientHeader",
    theme.has_gradient_header ? getGradient(theme.primary_color) : "",
  );
}

function updateThemeCssVariable(name: string, value: string): void {
  document.documentElement.style.setProperty(`--${name}`, value);
}

function updateThemeColorCssVariable(name: string, color: string): void {
  document.documentElement.style.setProperty(`--${name}`, `#${color}`);
  document.documentElement.style.setProperty(
    `--${name}Decimals`,
    convertHexToDecimals(color),
  );
}
