export const COLOR_OK = "#008040";
export const COLOR_WARNING = "#FAB541";
export const COLOR_DANGER = "#e40046";

export const COLOR_WHITE = "#ffffff";

export const COLOR_BLACK_1 = "#2d2d2d";
export const COLOR_BLACK_2 = "#414141";
export const COLOR_BLACK_3 = "#707070";
export const COLOR_BLACK_4 = "#9f9f9f";
export const COLOR_BLACK_5 = "#cecece";
export const COLOR_BLACK_6 = "#eaeaea";
export const COLOR_BLACK_7 = "#f2f2f2";
export const COLOR_BLACK_8 = "#fafafa";

export const COLOR_BLUE_CANARY_1 = "#1c91fa";
export const COLOR_BLUE_CANARY_2 = "#55acfb";
export const COLOR_BLUE_CANARY_3 = "#8dc8fc";
export const COLOR_BLUE_CANARY_4 = "#c6e3f3";
export const COLOR_BLUE_CANARY_5 = "#e8f4ff";

export const COLOR_BLUE_DARK_1 = "#2858c4";
export const COLOR_BLUE_DARK_2 = "#5e82d3";
export const COLOR_BLUE_DARK_3 = "#93abe1";
export const COLOR_BLUE_DARK_4 = "#c9d5f0";
export const COLOR_BLUE_DARK_5 = "#eaeef9";

export const COLOR_PINK_1 = "#f16682";
export const COLOR_PINK_2 = "#f48ca1";
export const COLOR_PINK_3 = "#f8b2c0";
export const COLOR_PINK_4 = "#fbd9e0";
export const COLOR_PINK_5 = "#fef0f3";

export const COLOR_LIGHT_GREEN_1 = "#22d39a";
export const COLOR_LIGHT_GREEN_2 = "#59deb3";
export const COLOR_LIGHT_GREEN_3 = "#90e9cc";
export const COLOR_LIGHT_GREEN_4 = "#c8f4e6";
export const COLOR_LIGHT_GREEN_5 = "#e9fbf5";

export const COLOR_DARK_GREEN_1 = "#008040";
export const COLOR_DARK_GREEN_2 = "#59deb3";
export const COLOR_DARK_GREEN_3 = "#90e9cc";
export const COLOR_DARK_GREEN_4 = "#c8f4e6";
export const COLOR_DARK_GREEN_5 = "#e9fbf5";

export const COLOR_WHEAT_1 = "#fab541";
export const COLOR_WHEAT_2 = "#fbc770";
export const COLOR_WHEAT_3 = "#fcdaa0";
export const COLOR_WHEAT_4 = "#feeccf";
export const COLOR_WHEAT_5 = "#fff8ec";

export const COLOR_PURPLE_1 = "#9b51e0";
export const COLOR_PURPLE_2 = "#bb6bd9";
export const COLOR_PURPLE_3 = "#cda8ef";
export const COLOR_PURPLE_4 = "#e6d3f7";
export const COLOR_PURPLE_5 = "#f5eefc";

export const COLOR_RED_1 = "#e40046";
export const COLOR_RED_2 = "#eb4074";
export const COLOR_RED_3 = "#f59fba";
export const COLOR_RED_4 = "#f8bfd1";
export const COLOR_RED_5 = "#fce6ed";
