<script setup lang="ts">
import { computed } from "vue";

import { useTheme } from "./ThemeStore";

const props = defineProps<{
  color?: string | null;
}>();

const theme = useTheme();

const loadingBorder = computed(() => {
  const color = props.color || theme.fontColorSecondary;
  return `2px solid ${color}`;
});
</script>

<template>
  <span :class="$style.loading" :style="{ border: loadingBorder }"> </span>
</template>

<style lang="scss" module>
.loading {
  animation: canaryLoadingSpinAround 0.5s infinite linear;
  border-radius: 9999px;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

@keyframes canaryLoadingSpinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
