<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "CanaryModal",
  setup() {
    const modalRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      modalRef.value?.focus();
    });

    onUnmounted(() => {
      modalRef.value?.blur();
    });

    return {
      modalRef,
    };
  },
});
</script>

<template>
  <div ref="modalRef" role="dialog" :class="$style.modal" tabindex="-1">
    <slot />
  </div>
</template>

<style lang="scss" module>
@import "shared/styles/base/colors.scss";

.modal {
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  background-color: var(--canaryThemeBackgroundColor);

  &:focus-visible,
  &:focus {
    outline: none;
  }

  &::after {
    border-radius: 8px;
  }
}
</style>
