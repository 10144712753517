// Build a map of regional vars because process.env["KEY"] cannot be referenced dynamically.
// All references to process.env. are replaced at build time by vite.
const regionalConfigVars = {
  VUE_APP_SITE_URL: process.env.VUE_APP_SITE_URL,
  VUE_APP_SITE_URL_EU: process.env.VUE_APP_SITE_URL_EU,

  VUE_APP_STRIPE_PUBLISHABLE_KEY: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  VUE_APP_STRIPE_PUBLISHABLE_KEY_EU:
    process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_EU,

  VUE_APP_STRIPE_SANDBOX_PUBLISH_KEY:
    process.env.VUE_APP_STRIPE_SANDBOX_PUBLISH_KEY,
  VUE_APP_STRIPE_SANDBOX_PUBLISH_KEY_EU:
    process.env.VUE_APP_STRIPE_SANDBOX_PUBLISH_KEY_EU,

  VUE_APP_VGS_PROXY_URL: process.env.VUE_APP_VGS_PROXY_URL,
  VUE_APP_VGS_PROXY_URL_EU: process.env.VUE_APP_VGS_PROXY_URL_EU,

  VUE_APP_AMPLITUDE_API_KEY: process.env.VUE_APP_AMPLITUDE_API_KEY,
  VUE_APP_AMPLITUDE_API_KEY_EU: process.env.VUE_APP_AMPLITUDE_API_KEY_EU,

  VUE_APP_HELLO_SIGN_CLIENT_ID: process.env.VUE_APP_HELLO_SIGN_CLIENT_ID,
  VUE_APP_HELLO_SIGN_CLIENT_ID_EU: process.env.VUE_APP_HELLO_SIGN_CLIENT_ID_EU,

  VUE_APP_STRIPE_PAYMENT_GATEWAY_UUID:
    process.env.VUE_APP_STRIPE_PAYMENT_GATEWAY_UUID,
  VUE_APP_STRIPE_PAYMENT_GATEWAY_UUID_EU:
    process.env.VUE_APP_STRIPE_PAYMENT_GATEWAY_UUID_EU,

  VUE_APP_STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID:
    process.env.VUE_APP_STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID,
  VUE_APP_STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID_EU:
    process.env.VUE_APP_STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID_EU,

  VUE_APP_TREMENDOUS_CLIENT_ID: process.env.VUE_APP_TREMENDOUS_CLIENT_ID,
  VUE_APP_TREMENDOUS_CLIENT_ID_EU: process.env.VUE_APP_TREMENDOUS_CLIENT_ID_EU,

  VUE_APP_DEMO_TREMENDOUS_CLIENT_ID:
    process.env.VUE_APP_DEMO_TREMENDOUS_CLIENT_ID,
  VUE_APP_DEMO_TREMENDOUS_CLIENT_ID_EU:
    process.env.VUE_APP_DEMO_TREMENDOUS_CLIENT_ID_EU,

  VUE_APP_SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN,
  VUE_APP_SENTRY_DSN_EU: process.env.VUE_APP_SENTRY_DSN_EU,

  VUE_APP_TIPPING_S3_BUCKET_URL:
    "https://canary-public-static.s3.us-west-2.amazonaws.com/tipping",
  VUE_APP_TIPPING_S3_BUCKET_URL_EU: "",

  VUE_APP_METICULOUS_RECORDING_TOKEN:
    process.env.VUE_APP_METICULOUS_RECORDING_TOKEN,
  VUE_APP_METICULOUS_RECORDING_TOKEN_EU:
    process.env.VUE_APP_METICULOUS_RECORDING_TOKEN,

  VUE_APP_WYNDHAM_CHAT_SOURCE: process.env.VUE_APP_WYNDHAM_CHAT_SOURCE,
  VUE_APP_WYNDHAM_CHAT_SOURCE_EU: process.env.VUE_APP_WYNDHAM_CHAT_SOURCE_EU,

  VUE_APP_WYNDHAM_CHAT_LANG: process.env.VUE_APP_WYNDHAM_CHAT_LANG,
  VUE_APP_WYNDHAM_CHAT_LANG_EU: process.env.VUE_APP_WYNDHAM_CHAT_LANG_EU,

  VUE_APP_WYNDHAM_CHAT_SCRT2URL: process.env.VUE_APP_WYNDHAM_CHAT_SCRT2URL,
  VUE_APP_WYNDHAM_CHAT_SCRT2URL_EU:
    process.env.VUE_APP_WYNDHAM_CHAT_SCRT2URL_EU,

  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_1:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_1,
  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_1_EU:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_1_EU,

  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_2:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_2,
  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_2_EU:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_2_EU,

  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_3:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_3,
  VUE_APP_WYNDHAM_CHAT_INIT_PARAM_3_EU:
    process.env.VUE_APP_WYNDHAM_CHAT_INIT_PARAM_3_EU,
};

type RegionalConfigKey = Extract<
  keyof typeof regionalConfigVars,
  `VUE_APP_${string}`
>;
type ConfigKey = Exclude<RegionalConfigKey, `${string}_EU`>;

function getConfigKeyForRegion(key: ConfigKey): RegionalConfigKey {
  if (window.location.hostname.startsWith("eu.")) {
    return `${key}_EU`;
  } else {
    return key;
  }
}

function isRegionalConfigKey(key: ConfigKey): boolean {
  return `${key}_EU` in regionalConfigVars;
}

function getConfigVar<T extends string | null>(
  key: RegionalConfigKey,
  defaultValue: T = "" as T,
): string | T {
  return regionalConfigVars[key] ?? defaultValue;
}

export function getRegionalConfigVar<T extends string | null>(
  key: ConfigKey,
  defaultValue: T = "" as T,
): string | T {
  if (!isRegionalConfigKey(key)) {
    throw new Error(
      `Config ${key} is not a regional variable. It should be accessed using process.env`,
    );
  }

  return getConfigVar(getConfigKeyForRegion(key), defaultValue);
}
