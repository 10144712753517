<script lang="ts">
import CloseIcon from "@mdi/svg/svg/close.svg";
import { defineComponent, PropType } from "vue";
import type { TranslateResult } from "vue-i18n";

import CanaryButton, {
  ButtonColor,
  ButtonSize,
  ButtonType,
} from "canary-ui/src/CanaryButton.vue";
import CanaryModal from "canary-ui/src/modals/CanaryModal.vue";

export enum ConfirmType {
  NORMAL = "normal",
  DANGER = "danger",
}

export default defineComponent({
  components: {
    CanaryModal,
    CanaryButton,
  },
  props: {
    title: {
      type: String as PropType<TranslateResult | string>,
      required: true,
    },
    message: {
      type: String as PropType<TranslateResult | string>,
      required: false,
      default: "",
    },
    type: {
      type: String as PropType<ConfirmType>,
      default: ConfirmType.NORMAL,
    },
    cancelText: {
      type: String as PropType<TranslateResult | string>,
      default: "Cancel",
    },
    confirmText: {
      type: String as PropType<TranslateResult | string>,
      default: "Yes",
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    isCancelLoading: {
      type: Boolean,
      default: false,
    },
    isConfirmLoading: {
      type: Boolean,
      default: false,
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["cancel", "confirm"],
  setup() {
    return {
      CloseIcon,
      ButtonColor,
      ButtonSize,
      ButtonType,
      ConfirmType,
    };
  },

  computed: {
    cancelPendo(): string {
      return `${this.title.toString().toLowerCase()} - cancel`;
    },
    confirmPendo(): string {
      return `${this.title.toString().toLowerCase()} - confirm`;
    },
  },
});
</script>
<template>
  <CanaryModal :class="$style.modal">
    <div :class="$style.content">
      <div :class="$style.title">
        {{ title }}
        <CanaryButton
          v-if="showCloseIcon"
          :type="ButtonType.ICON_SECONDARY"
          :icon="CloseIcon"
          :size="ButtonSize.COMPACT"
          @click="$emit('cancel')"
        >
        </CanaryButton>
        <slot name="rightAddon"></slot>
      </div>
      <div :class="$style.message">
        <slot>{{ message }}</slot>
      </div>

      <div :class="$style.footer">
        <CanaryButton
          :size="ButtonSize.NORMAL"
          :type="ButtonType.OUTLINED"
          :class="$style.button"
          :is-loading="isCancelLoading"
          :is-disabled="isCancelLoading || isConfirmLoading"
          :data-pendo="cancelPendo"
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </CanaryButton>
        <CanaryButton
          :size="ButtonSize.NORMAL"
          :color="
            type === ConfirmType.DANGER
              ? ButtonColor.DANGER
              : ButtonColor.NORMAL
          "
          :class="$style.button"
          :is-loading="isConfirmLoading"
          :is-disabled="isConfirmDisabled || isConfirmLoading"
          :data-pendo="confirmPendo"
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </CanaryButton>
      </div>
    </div>
  </CanaryModal>
</template>

<style lang="scss" module>
@import "shared/styles/base/typography.scss";

$gutter-gap-spacing: 16px;

.modal {
  margin: 16px;
  width: 480px;
  padding: 24px;
}

.title {
  @include font-style($subtitle-lg, 500);
  display: flex;
  justify-content: space-between;
}

.message {
  padding: 16px 0 24px;
  color: var(--canaryThemeFontColorSecondary);
  @include font-style($body);
}

.footer {
  align-items: center;
  display: flex;
  gap: $gutter-gap-spacing;
  justify-content: flex-end;
}
</style>
