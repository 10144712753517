import axios from "axios";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

// TODO: this should not be needed and only kept around for compatibility
// import directly from "axios"
const instance = axios.create({});

export const isAxiosError = axios.isAxiosError;

export default instance;
