import * as Sentry from "@sentry/vue";
import axios from "axios";

/** Standard errors that should be excluded from Sentry. */
const EXCLUDE_ERRORS = [
  // From 'Mobile Safari UI/WKWebView' - Apple Mail error.
  "wpmobileappStartLoading",
  // From 'Mobile Safari UI/WKWebView' - Apple Mail error.
  "wpmobileappStopLoading",
  // 'Mobile Safari' error.
  "instantSearchSDKJSBridgeClearHighlight",
  // 'Mobile Edge' error.
  "updateAndCleanExtractedContent",
  // File load issue - File does indeed exist in the CDN.
  "Unable to preload CSS for",
  // File load issue - File does indeed exist in the CDN.
  "Failed to fetch dynamically imported module",
];

/** Standard errors that should be remapped to a single fingerprint. */
const REMAPPED_ERRORS = {
  // Webpack JS ChunkLoadError. These are are network issues.
  ChunkLoadError: "chunk_error_js",
  // Webpack CSS ChunkLoadError. These are are network issues.
  "Loading CSS chunk": "chunk_error_css",
};

/** Axios error codes that should be excluded from Sentry. */
const EXCLUDE_AXIOS_CODES = [
  "ERR_NETWORK",
  "ERR_CANCELED",
  "ECONNABORTED",
  "ETIMEDOUT",
];

export function filterStandardEvents(
  event: Sentry.Event,
  hint?: Sentry.EventHint,
): Sentry.Event | null {
  if (!hint?.originalException) return event;
  const error = hint.originalException ?? "";
  const errorMessage =
    error instanceof Error
      ? `${error.name} ${error.message}`
      : error.toString();

  // Ignore all axios cancellation errors.
  if (axios.isCancel(error)) {
    return null;
  }

  // Ignore specific Axios network errors.
  if (
    axios.isAxiosError(error) &&
    isIncluded(EXCLUDE_AXIOS_CODES, error.code ?? "")
  ) {
    return null;
  }

  // Ignore Chrome/Safari network error when disconnected.
  if (error instanceof Error && error.message == "Network Error") {
    return null;
  }

  // Remap errors to always group consistently in Sentry.
  for (const key in REMAPPED_ERRORS) {
    if (errorMessage.indexOf(key) != -1) {
      event.fingerprint = [
        REMAPPED_ERRORS[key as keyof typeof REMAPPED_ERRORS],
      ];
      return event;
    }
  }

  // Ignore errors in the exclude list.
  for (let i = 0; i < EXCLUDE_ERRORS.length; i++) {
    if (errorMessage.indexOf(EXCLUDE_ERRORS[i]) != -1) {
      return null;
    }
  }

  return event;
}

/** Avoids using `Array.prototype.includes` to support all browsers. */
function isIncluded<T>(values: T[], check: T): boolean {
  for (let i = 0; i < values.length; i++) {
    if (values[i] == check) {
      return true;
    }
  }
  return false;
}
