import { IVueI18n, type TranslateResult } from "vue-i18n";

import assertUnreachable from "shared/helpers/assertUnreachable";

export const RTL_LANGUAGES = ["ar"];
export const DEFAULT_LANGUAGE = "en" as LanguageCode;

export const languages = {
  EN: "en",
  CA: "ca",
  ES: "es",
  IT: "it",
  DE: "de",
  FR: "fr",
  AR: "ar",
  ZH: "zh",
  JA: "ja",
  KM: "km",
  NL: "nl",
  UK: "uk",
  PL: "pl",
  PT_BR: "pt-BR",
  PT: "pt",
  EL: "el",
  CS: "cs",
  ZH_HK: "zh-HK",
  KA_GE: "ka-GE",
  TR: "tr",
  KO: "ko",
  HU: "hu",
  TH: "th",
  SK: "sk",
  VI: "vi",
} as const;

export type ValueKey = keyof typeof languages;
export type LanguageCode = (typeof languages)[keyof typeof languages];

export const languageNames: Record<LanguageCode, string> = {
  [languages.EN]: "English",
  [languages.ES]: "Español",
  [languages.CA]: "Català", // Catalan
  [languages.IT]: "Italiano",
  [languages.DE]: "Deutsch",
  [languages.FR]: "Français",
  [languages.AR]: "عربى",
  [languages.ZH]: "简体",
  [languages.JA]: "日本語",
  [languages.KM]: "ខ្មែរ",
  [languages.NL]: "Nederlands",
  [languages.UK]: "украї́нська",
  [languages.PL]: "Polski",
  [languages.PT_BR]: "Português do Brasil",
  [languages.PT]: "Português",
  [languages.EL]: "Ελληνικά",
  [languages.CS]: "Čeština",
  [languages.ZH_HK]: "繁體",
  [languages.KA_GE]: "ქართული",
  [languages.TR]: "Turkish",
  [languages.KO]: "한국어",
  [languages.HU]: "Magyar",
  [languages.TH]: "ภาษาไทย",
  [languages.SK]: "Slovenčina",
  [languages.VI]: "Tiếng Việt",
};

export const englishLanguageNames: Record<LanguageCode, string> = {
  [languages.EN]: "English",
  [languages.ES]: "Spanish",
  [languages.CA]: "Catalan",
  [languages.IT]: "Italian",
  [languages.DE]: "German",
  [languages.FR]: "French",
  [languages.AR]: "Arabic",
  [languages.ZH]: "Chinese",
  [languages.JA]: "Japanese",
  [languages.KM]: "Khmer",
  [languages.NL]: "Dutch",
  [languages.UK]: "Ukrainian",
  [languages.PL]: "Polish",
  [languages.PT_BR]: "Portuguese (Brazil)",
  [languages.PT]: "Portuguese",
  [languages.EL]: "Greek",
  [languages.CS]: "Czech",
  [languages.ZH_HK]: "Chinese Traditional (Hong Kong)",
  [languages.KA_GE]: "Georgian (Georgia)",
  [languages.TR]: "Turkish",
  [languages.KO]: "Korean",
  [languages.HU]: "Hungarian",
  [languages.TH]: "Thai",
  [languages.SK]: "Slovak",
  [languages.VI]: "Vietnamese",
};

export function getLanguageName(
  lang: LanguageCode,
  i18n?: IVueI18n,
): string | TranslateResult {
  if (!i18n) {
    return englishLanguageNames[lang];
  }
  // Use switch to allow vue-i18n-extract to statically analyze the language names
  switch (lang) {
    case languages.EN:
      return i18n.t("languages.en");
    case languages.ES:
      return i18n.t("languages.es");
    case languages.CA:
      return i18n.t("languages.ca");
    case languages.IT:
      return i18n.t("languages.it");
    case languages.DE:
      return i18n.t("languages.de");
    case languages.FR:
      return i18n.t("languages.fr");
    case languages.AR:
      return i18n.t("languages.ar");
    case languages.ZH:
      return i18n.t("languages.zh");
    case languages.JA:
      return i18n.t("languages.ja");
    case languages.KM:
      return i18n.t("languages.km");
    case languages.NL:
      return i18n.t("languages.nl");
    case languages.UK:
      return i18n.t("languages.uk");
    case languages.PL:
      return i18n.t("languages.pl");
    case languages.PT_BR:
      return i18n.t("languages.pt-BR");
    case languages.PT:
      return i18n.t("languages.pt");
    case languages.EL:
      return i18n.t("languages.el");
    case languages.CS:
      return i18n.t("languages.cs");
    case languages.ZH_HK:
      return i18n.t("languages.zh-HK");
    case languages.KA_GE:
      return i18n.t("languages.ka-GE");
    case languages.TR:
      return i18n.t("languages.tr");
    case languages.KO:
      return i18n.t("languages.ko");
    case languages.HU:
      return i18n.t("languages.hu");
    case languages.TH:
      return i18n.t("languages.th");
    case languages.SK:
      return i18n.t("languages.sk");
    case languages.VI:
      return i18n.t("languages.vi");
    default:
      assertUnreachable(lang);
  }
}

export function isLanguageCode(lang?: unknown): lang is LanguageCode {
  return (
    typeof lang === "string" &&
    Object.values(languages).includes(lang as LanguageCode)
  );
}
